import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { Query, Gym } from "@types"

export default () => {
  const data: Query = useStaticQuery(graphql`
    query {
      allGym {
        edges {
          node {
            id
            name
            image
            address
          }
        }
      }
    }
  `)
  const items = data.allGym.edges.map(edge => <GymListItem key={edge.node.id} gym={edge.node} />)
  return (
    <Container>
      <section className="hero">
        <div className="hero-body">
          <div className="section has-text-centered">
            <h1 className="title">
              Everyclimb 🧗‍♂️
            </h1>
            <h2 className="subtitle">
              에브리클라임
            </h2>
          </div>
        </div>
      </section>
      <GymListSection>
        <Title>클라이밍장 찾기 🔍</Title>
        {items}
      </GymListSection>
    </Container>
  )
}

const Container = styled.div.attrs({ className: 'container' })`
`

const Title = styled.h1.attrs({ className: 'title' })`
`

const GymListSection = styled.section.attrs({ className: 'section' })`
`

const GymListItem = (props: { gym: Gym }) => {
  const gym = props.gym
  return (
    <BoxLink to={`/gyms/${gym.id}`} key={gym.id} className="box">
      <article className="media">
        <div className="media-left">
          <figure className="image is-square is-96x96">
            <GymListItemImage src={`/images/gyms/${gym.image!}`} alt={gym.name!} />
          </figure>
        </div>
        <div className="media-content">
          <div className="content">
            <p>
              <strong>{gym.name}</strong>
              <br />
              {gym.address}
            </p>
          </div>
        </div>
      </article>
    </BoxLink>
  )
}

const BoxLink = styled(Link)`
  &:hover, &:active, &:focus {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1) !important;
  }

`
const GymListItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
